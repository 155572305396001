// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-fanart-tsx": () => import("./../../../src/pages/fanart.tsx" /* webpackChunkName: "component---src-pages-fanart-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invitados-tsx": () => import("./../../../src/pages/invitados.tsx" /* webpackChunkName: "component---src-pages-invitados-tsx" */),
  "component---src-pages-programa-tsx": () => import("./../../../src/pages/programa.tsx" /* webpackChunkName: "component---src-pages-programa-tsx" */)
}

