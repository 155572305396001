import React from 'react'

// Components
import UserProvider from './src/context/User/User.state'

// Style
import './src/styles/index.scss'

export const wrapRootElement = ({ element }) => (
  <UserProvider>{element}</UserProvider>
)
